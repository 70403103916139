import React from "react"
import { Helmet } from "react-helmet"
import Divedowns, { DivedownBox } from "../components/divedowns"
import Ad from "../components/ad"

import { DetailedAttributeList } from "../components/attribute"

import Layout from "../components/layout"
import { graphql } from "gatsby"

import HeaderPic from "../components/headerpic"
import headerPic from "../../static/headerpics/lexikon.png"

export default function Namenslexikon({ data }) {
  const with_two = data.pagesJson.divedowns
    .filter((divedown) => divedown.attribute.category === 'Zwei Anfangsbuchstaben')
  const with_three = data.pagesJson.divedowns
    .filter((divedown) => divedown.attribute.category === 'Drei Anfangsbuchstaben')
  const syllables = data.pagesJson.divedowns
    .filter((divedown) => divedown.attribute.category === 'Silben')
    .map(divedown => divedown.attribute)


  return (
    <Layout>
      <Helmet>
        <title>Namenslexikon - 16151 Vornamen nach Alphabet</title>
        <meta name="description"
          content="16151 Vornamen nach Alphabet, Länge, Anzahl Silben und mehr." />
        <meta name="keywords"
          content="vornamen, lang, kurz, alphabet, silben, namenslexikon" />
        <link rel="canonical" href="/namenslexikon" />
      </Helmet>

      <div className="paper" >
        <HeaderPic imagePath={headerPic} vertical_focus="20%" />
        <h1>Namenslexikon - Kurze und lange Namen nach Alphabet</h1>
        <p>In unserem Namenslexikon finden Sie mehr als 15'000 Vornamen alphabetisch geordnet.
        Klicken Sie auf einen Buchstaben, um zur Vornamenauswahl zu gelangen.
        Über das Suchfeld "Schreibweise" können Sie eingeben,
        welche Buchstaben Ihr Lieblingsname enthalten soll
        und aus wie vielen Silben er bestehen soll.
        Ob Sie es lieber kurz und knapp mögen oder lang und melodiös
        - hier finden Sie bestimmt den perfekten Namen für Ihr Kind.
          </p>
      </div>
      <div className="paper" >
        <h2>Nach Alphabet</h2>
        <DivedownBox
          title="Nach Anfangsbuchstaben"
          divedowns={data.pagesJson.divedowns
            .filter((divedown) => divedown.attribute.category === 'Anfangsbuchstaben')
            .sort((a, b) => (a.attribute.id > b.attribute.id) ? 1 : -1)}
          initial_limit={100}
        />
      </div>
      <Ad id="adnz_maxiboard_1" />
      <div className="paper">
        <h2>Nach Anzahl Silben</h2>
        <DetailedAttributeList attributes={syllables} show_category={false} />
      </div>
      <Divedowns divedowns={with_two.concat(with_three)} />
      <Ad id="adnz_maxiboard_2" />
    </Layout>
  )
}

export const query = graphql`
  query {
    pagesJson(url: {eq: ""}) {
      divedowns {
        attribute {
          imagePath
          associated_page_url
          category
          id
          latest_births
          popularity
          rank
          title
          trend
          births {
            births
            years
          }
        }
        number_of_names
        subpage_url
      }
    }
  }
  `